<template>
  <div
      class="draggable-handle"
      :class="{'draggable-handle--enabled': enabled}"
      @click.stop="">
    <DragIcon
        :title="t('dragHelp')">
      {{ t('drag') }}
    </DragIcon>
  </div>
</template>
<i18n>
{
  "nl": {
    "drag": "Versleep",
    "dragHelp": "Sleep om te verplaatsen"
  }
}
</i18n>
<script>

import { useI18n } from 'vue-i18n';
import DragIcon from '@carbon/icons-vue/es/draggable/16.js';

export default {
  components: { DragIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    context: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};

</script>
<style>
.draggable-handle {
  @media (any-hover: hover) {

    &--enabled:not(&--dragging):hover {
      opacity: .4;
    }

    &-root:has(&--enabled:hover):not(:has(&--dragging)) {
      z-index: var(--layer-page-overlay);

      box-shadow: var(--dimension-xx-small) var(--dimension-xx-small) var(--dimension-large) rgba(var(--rgb-default-foreground), .1);

      /*noinspection CssInvalidFunction*/
      transform: translate3d(
          calc(-1 * var(--dimension-hair-line)),
          calc(-1 * var(--dimension-hair-line)),
          0) skewX(1deg);
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  min-width: var(--dimension-medium-large);

  opacity: 0;
  transform: translate3d(-25%, 0, 0);

  transition: opacity calc(2 * var(--animation-default-duration)) ease, transform calc(2 * var(--animation-default-duration)) ease;

  pointer-events: none;
  justify-self: stretch;

  &--enabled {
    opacity: .5;

    transform: translate3d(0, 0, 0);
    cursor: grab;

    pointer-events: auto;
  }
}
</style>
