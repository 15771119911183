<template>
  <input
      :id="id"
      ref="input"
      :type="type"
      :name="name"
      :disabled="readOnly"
      :tabindex="tabindex"
      class="input"
      :class="{
        'input--error': validator && validator.$error,
        'input--read-only': readOnly,
    }"
      @blur="() => validateOnBlur && validate()">
</template>

<script>
import {
  ref,
  toRefs,
} from 'vue';

export default {
  props: {
    name: {
      type: String,
      default: () => undefined,
    },
    id: {
      type: String,
      default: () => undefined,
    },
    type: {
      type: String,
      default: () => 'text',
    },
    validator: {
      type: Object,
      default: () => null,
    },
    validateOnBlur: {
      type: Boolean,
      default: () => false,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
    tabindex: {
      type: Number,
      default: () => null,
    },
  },
  setup(props) {
    const { validator } = toRefs(props);
    const input = ref(null);
    return {
      input,
      validate: () => validator.value?.$touch(),
      select() {
        input.value?.select();
      },
      focus() {
        input.value?.focus();
      },
      blur() {
        input.value?.blur();
      },
    };
  },
};
</script>

<style>
.input {
  --input-font-size: 1rem;
  width: 100%;
  min-height: 2rem;
  margin: 0;

  color: var(--color-default-foreground);

  background-color: transparent;
  border: inherit;
  outline: none;

  font-family: var(--font-sans-serif);
  font-size: var(--input-font-size);
  font-weight: 400;
  line-height: 2rem;

  appearance: none;

  &--error {
    border-color: var(--color-red);
  }

  &--read-only {
    opacity: .5;

    pointer-events: none;
  }

}
</style>
