<template>
  <Pane class="timed-token-pane">
    <span
        class="timed-token-pane__code"
        :style="{'--timed-token-percentage': `${percentage}%`}">

      <span
          v-for="(char, idx) in code"
          :key="idx"
          class="timed-token-pane__char">
        {{ char }}
      </span>
    </span>
  </Pane>
</template>
<script>
import {
  computed,
  toRefs,
} from 'vue';
import { differenceInMilliseconds } from 'date-fns';
import useTimer from '../timer/timer.js';
import Pane from '../panels/Pane.vue';

export default {
  components: { Pane },
  props: {
    code: {
      type: String,
      required: true,
    },
    valid: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // refs
    const { valid } = toRefs(props);

    // uses
    const now = useTimer(computed(() => valid.value?.end));

    // computed
    const diff = computed(() => differenceInMilliseconds(valid.value.end, valid.value.start));
    const done = computed(() => differenceInMilliseconds(now.value, valid.value.start));
    const percentage = computed(() => {
      const perc = (100.0 * (done.value / diff.value)).toFixed(1);
      if (perc <= 0) {
        return 0;
      }
      if (perc > 100) {
        return 100;
      }
      return perc;
    });

    return {
      // uses
      now,

      // computed
      percentage,
    };
  },
};
</script>
<style>
.timed-token-pane {
  &__code {
    --timed-token-percentage: 0%;

    display: flex;
    justify-content: space-around;

    padding: var(--dimension-medium);

    background-color: var(--color-background);

    background-image: linear-gradient(
        to right,
        var(--color-primary-complement-background) 0,
        var(--color-primary-complement-background) var(--timed-token-percentage),
        transparent var(--timed-token-percentage),
        transparent 100%
    );

  }

  &__char {
    font-family: var(--font-monospace);

    font-size: 2.5rem;
    font-weight: 500;
    letter-spacing: var(--dimension-medium);

    text-align: center;
    font-variant-numeric: tabular-nums;
  }
}
</style>
