<template>
  <Pane
      tag="section"
      :class="{'section-panel--sticky': sticky}"
      class="section-panel">
    <header class="section-panel__header">
      <slot name="header">
        <div class="section-panel__title">
          <slot
              v-if="!loading"
              name="title">
            <SectionPanelTitle>
              {{ title }}
            </SectionPanelTitle>
          </slot>
          <span v-else>&nbsp;</span>
        </div>
      </slot>
      <aside
          v-if="$slots.actions"
          class="section-panel__actions">
        <slot name="actions" />
      </aside>
    </header>
    <slot name="content">
      <Pane
          class="section-panel__main">
        <slot />
      </Pane>
    </slot>
  </Pane>
</template>
<script>
import SectionPanelTitle from './SectionPanelTitle.vue';
import Pane from './Pane.vue';

export default {
  components: { Pane, SectionPanelTitle },
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => null,
    },
    sticky: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
<style>
.section-panel {
  gap: var(--dimension-small);

  &__header {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: row;

    align-items: flex-end;

    min-width: 0;
  }

  &--sticky &__header {
    position: sticky;
    top: 0;

    background-color: rgba(var(--rgb-default-background), .5);
    backdrop-filter: blur(5px);
  }

  &__title {
    flex: 1;
  }

  &__main {
    gap: var(--dimension-small);
    flex: 1;
  }

  &__actions {
    display: flex;
    gap: var(--dimension-x-small);
    flex-direction: row;
    justify-self: flex-end;
  }

}

@media screen and (max-width: 600px) {
  .section-panel {
    &__header {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
    }

    &__actions {
      flex-direction: column;
      justify-self: stretch;

      width: 100%;
    }
  }
}
</style>
