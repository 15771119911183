<template>
  <a
      v-if="isExternalLink || newTab"
      v-bind="$attrs"
      ref="el"
      :href="href"
      rel="noopener noreferrer"
      :class="classes"
      class="icon-button"
      :title="title"
      :target="target">
    <span class="icon-button__label">
      <slot />
    </span>
    <slot name="sections" />
    <span class="icon-button__icon">
      <slot name="icon">
        <ExternalIcon v-if="target === '_blank'" />
        <RightIcon v-else />
      </slot>
    </span>
  </a>
  <router-link
      v-else-if="isLink"
      v-bind="$props"
      custom>
    <a
        v-bind="$attrs"
        ref="el"
        :href="href"
        class="icon-button"
        :class="classes"
        :title="title"
        @click="handleClick">
      <span class="icon-button__label">
        <slot />
      </span>
      <slot name="sections" />
      <span class="icon-button__icon">
        <slot name="icon">
          <LeftIcon v-if="reversed" />
          <RightIcon v-else />
        </slot>
      </span>
    </a>
  </router-link>
  <button
      v-else
      ref="el"
      :class="classes"
      :type="(submit) ? 'submit': 'button'"
      :disabled="!enabled"
      class="icon-button"
      :title="title"
      @click="handleClick">
    <div class="icon-button__label">
      <slot />
    </div>
    <slot name="sections" />
    <div class="icon-button__icon">
      <template v-if="!loading">
        <slot name="icon">
          <LeftIcon v-if="reversed" />
          <RightIcon v-else />
        </slot>
      </template>
      <template v-else>
        <slot name="icon-loader">
          <LoadingIcon />
        </slot>
      </template>
    </div>
  </button>
</template>

<script>
import {
  computed,
  inject,
  ref,
  toRefs,
  watch,
} from 'vue';
import LeftIcon from '@carbon/icons-vue/es/arrow--left/24.js';
import RightIcon from '@carbon/icons-vue/es/arrow--right/24.js';
import LoadingIcon from '@carbon/icons-vue/es/data--2/24.js';
import ExternalIcon from '@carbon/icons-vue/es/new-tab/24.js';

import {
  RouterLink,
  useLink,
} from 'vue-router';

export default {
  components: {
    RightIcon,
    LeftIcon,
    LoadingIcon,
    ExternalIcon,
    RouterLink,
  },
  props: {
    // eslint-disable-next-line
    to: { ...RouterLink.props.to, required: false },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    reversed: {
      type: Boolean,
      default: () => false,
    },
    iconOnly: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    prevent: {
      type: Boolean,
      default: () => false,
    },
    debounceDoubleClick: {
      type: Boolean,
      default: () => true,
    },
    submit: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: [String, Array],
      default: () => 'primary',
    },
    title: {
      type: String,
      default: () => null,
    },
    target: {
      type: String,
      default: () => '_blank',
    },
    newTab: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['click'],

  setup(props, { emit }) {
    const {
      type,
      enabled,
      reversed,
      loading,
      debounceDoubleClick,
      prevent,
      submit,
      iconOnly,
      to,
    } = toRefs(props);
    const el = ref();
    const link = (to.value) ? useLink({ to }) : null;
    const doubleClickPrevention = ref(false);

    const isLink = computed(() => !!link);
    const isExternalLink = computed(() => to.value?.external || (typeof to.value === 'string'));
    const withinForm = inject('withinForm', ref(false));
    const preventPropagation = computed(() => prevent.value
        || (!!withinForm.value && !submit.value));

    watch(type, () => {
      el.value?.blur();
    });

    return {
      el,
      isActive: computed(() => link?.isActive.value),
      isExternalLink,
      isLink,
      href: computed(() => {
        if ((typeof to.value === 'string')) {
          return to.value;
        }
        return link?.href.value;
      }),
      route: computed(() => link?.route.value),
      doubleClickPrevention,
      classes: computed(() => ({
        [`icon-button--${type.value}`]: true,
        'icon-button--disabled': !enabled.value,
        'icon-button--reversed': reversed.value,
        'icon-button--loading': loading.value,
        'icon-button--prevent': doubleClickPrevention.value,
        'icon-button--icon-only': iconOnly.value,
      })),
      handleClick(ev) {
        if (preventPropagation.value) {
          ev.preventDefault();
        }
        if (!doubleClickPrevention.value && enabled.value && !loading.value) {
          doubleClickPrevention.value = debounceDoubleClick.value;
          if (link) {
            link.navigate(ev);
          } else {
            emit('click', ev);
          }
          el.value?.blur();

          if (debounceDoubleClick.value) {
            setTimeout(() => {
              doubleClickPrevention.value = false;
            }, 300);
          }
        }

      },
    };
  },
};
</script>

<style>

.icon-button {
  --button-padding: .25rem;
  --button-label-padding: .625rem;
  --button-label-font-size: .75rem;
  --button-label-font-weight: 600;
  --button-default-position: left;
  --button-hover-position: right;
  --button-foreground: var(--color-default-foreground);
  --button-background: var(--color-default-background);
  --button-icon-foreground: var(--button-foreground);
  --button-icon-background: var(--button-background);
  @supports (background-color: color-mix(in srgb, white, white)) {
    --button-icon-background: color-mix(in srgb, var(--button-background), var(--color-default-foreground) 10%);
  }
  @media (any-hover: hover) {
    &:hover {
      color: var(--button-icon-foreground);
      background-position: var(--button-hover-position) center;
    }

    &:hover &__icon svg {
      transform: translate3d(.1rem, 0, 0);
    }

    &--icon-only:hover &__icon svg {
      transform: scale(90%);
    }
  }
  display: inline-flex; /* Safari messes up grid */
  flex-direction: row;
  align-items: stretch;

  margin: 0;

  padding: 0;
  overflow: hidden;

  color: var(--button-foreground);

  background-image: linear-gradient(
      to right,
      var(--button-background) 0,
      var(--button-background) 50%,
      var(--button-icon-background) 50%,
      var(--button-icon-background) 100%
  );
  background-position: var(--button-default-position) center;
  background-size: 200% 100%;
  border: 0;

  border-radius: var(--button-padding);
  outline: none;
  cursor: pointer;

  text-decoration: none;

  transition: background-position var(--animation-default-duration);

  appearance: none;

  &__label {
    display: flex;

    flex: 1;
    justify-content: center;
    align-items: center;

    padding: var(--button-label-padding);
    padding-left: calc(var(--button-label-padding) * 1.6);

    font-size: var(--button-label-font-size);
    font-weight: var(--button-label-font-weight);
    line-height: 1;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: calc(var(--button-padding) * 8);

    padding: var(--button-padding);

    color: var(--button-icon-foreground);
    background: var(--button-icon-background);

    svg {
      fill: currentColor;

      transition: transform var(--animation-default-duration) linear;
    }
  }

  &--icon-only &__label {
    display: none;
  }

  &--icon-only &__icon {
    color: var(--button-foreground);
    background-color: transparent;
  }

  &--disabled, &--loading {
    opacity: .5;
    cursor: not-allowed;

    pointer-events: none;
  }

  &--prevent {
    pointer-events: none;
  }

  &--primary {
    --button-foreground: var(--color-primary-foreground);
    --button-background: var(--color-primary-background);
    --button-icon-foreground: var(--color-primary-foreground);
    --button-icon-background: var(--color-primary-background);
    @supports (background-color: color-mix(in srgb, white, white)) {
      --button-icon-background: color-mix(in srgb, var(--color-primary-background), var(--color-default-foreground) 25%);
    }
  }

  &--secondary {
    --button-foreground: var(--color-foreground);
    --button-background: var(--color-background);
    --button-icon-foreground: var(--color-foreground);
    --button-icon-background: var(--color-background);
    @supports (background-color: color-mix(in srgb, white, white)) {
      --button-icon-background: color-mix(in srgb, var(--color-background), var(--color-default-foreground) 25%);
    }
  }

  &--action {
    --button-foreground: var(--color-default-foreground);
    --button-background: var(--color-default-background);
    --button-icon-foreground: var(--color-default-foreground);
    --button-icon-background: var(--color-default-background);
    @supports (background-color: color-mix(in srgb, white, white)) {
      --button-icon-background: color-mix(in srgb, var(--color-default-background), var(--color-default-foreground) 25%);
    }
  }

  &--destructive {
    --button-foreground: var(--color-default-foreground);
    --button-background: color-mix(in srgb, var(--color-error), var(--color-default-background) 80%);
    --button-icon-foreground: var(--color-default-background);
    --button-icon-background: color-mix(in srgb, var(--color-error), var(--color-default-foreground) 30%);
  }

  &--warning {
    --button-foreground: var(--color-charcoal);
    --button-background: var(--color-warning);
    --button-icon-foreground: var(--color-default-background);
    --button-icon-background: color-mix(in srgb, var(--color-warning), var(--color-default-foreground) 30%);
  }

  &--error {
    --button-foreground: var(--color-white);
    --button-background: var(--color-error);
    --button-icon-foreground: var(--color-default-background);
    --button-icon-background: color-mix(in srgb, var(--color-error), var(--color-default-foreground) 30%);
  }

  &--success {
    --button-foreground: var(--color-default-background);
    --button-background: var(--color-success);
    --button-icon-foreground: var(--color-default-background);
    --button-icon-background: color-mix(in srgb, var(--color-success), var(--color-default-foreground) 20%);
  }

  &--reversed {
    --button-default-position: right;
    --button-hover-position: left;

    background-image: linear-gradient(to right,
    var(--button-icon-background) 0,
    var(--button-icon-background) 50%,
    var(--button-background) 50%,
    var(--button-background) 100%
    );
    background-position: var(--button-default-position) center;
  }

  &--reversed &__label {
    order: 1;
  }

  &--icon-only {
    @media (any-hover: hover) {
      &:hover {
        background-position: left bottom;
      }

      &:hover &__icon {
        color: var(--button-icon-foreground);
      }
    }
    background-image: linear-gradient(to bottom,
    var(--button-background) 0,
    var(--button-background) 50%,
    var(--button-icon-background) 50%,
    var(--button-icon-background) 100%
    );
    background-position: left top;
    background-size: 100% 200%;
  }

  &:focus {
    color: var(--button-icon-foreground);
    background-position: var(--button-hover-position) center;
  }

}
</style>
