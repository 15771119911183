<template>
  <TextLabel
      tag="span"
      class="time-label">
    {{ timeFormatter.format(time) }}
  </TextLabel>
</template>
<script>
import { useI18n } from 'vue-i18n';
import TextLabel from './TextLabel.vue';
import { useTimeFormatter } from '../../utils/date.js';

export default {
  components: { TextLabel },

  props: {
    time: {
      type: Object,
      required: true,
    },
  },
  setup() {

    const { locale } = useI18n();
    const timeFormatter = useTimeFormatter(locale, '2-digit');

    return {

      timeFormatter,
    };
  },
};
</script>
<style>
.time-label {
  font-variant-numeric: tabular-nums;
}
</style>
