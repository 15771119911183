<template>
  <div
      class="branch-select"
      :class="{
      'branch-select--selected': modelValue === option,
      'branch-select--disabled': !enabled
    }">
    <RadioBox
        :name="name"
        :disabled="!enabled"
        :model-value="modelValue"
        :value="option"
        class="branch-select__radio"
        @update:model-value="$emit('update:model-value', option)">
      <slot name="label" />
    </RadioBox>
    <div
        v-if="modelValue === option && !!$slots.default"
        class="branch-select__content">
      <slot />
    </div>
  </div>
</template>

<script>

import RadioBox from '../widgets/forms/RadioBox.vue';

export default {
  components: { RadioBox },
  props: {
    name: {
      type: String,
      required: true,
    },
    option: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      default: () => true,
    },
    modelValue: {
      type: String,
      default: () => null,
      required: false,
    },
  },
  emits: ['update:model-value'],
};
</script>

<style>
.branch-select {
  display: grid;

  min-width: 0;

  opacity: 1;

  transition: opacity var(--animation-default-duration);

  &--selected {
    grid-template-rows: auto 1fr;
  }

  &--disabled {
    pointer-events: none;
  }

  &__content {
    min-width: 0;
    padding: var(--dimension-small) 0 var(--dimension-small) var(--dimension-medium);

    border-left: var(--dimension-line) solid var(--color-primary-background);
  }

}
</style>
