<template>
  <div
      class="horizontal-grid-pane">
    <slot />
  </div>
</template>
<style>
.horizontal-grid-pane {
  display: grid;
  grid-auto-flow: column;
  gap: var(--dimension-large);

}

@media screen and (max-width: 768px) {
  .horizontal-grid-pane {
    grid-auto-flow: row;
  }
}
</style>
