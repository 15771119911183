<template>
  <WindowPanel
      class="dialog-panel"
      :title="title"
      :loading="loading"
      @close="$emit('close')">
    <template #title>
      <slot name="title" />
    </template>
    <DialogActionPane>
      <template #main>
        <div class="dialog-panel__main">
          <div
              v-if="!!$slots.icon || type"
              class="dialog-panel__icon-container">
            <slot name="icon">
              <WarningIcon
                  v-if="type === 'warning'"
                  class="dialog-panel__icon" />
              <InfoIcon
                  v-else-if="type === 'info'"
                  class="dialog-panel__icon" />
            </slot>
          </div>
          <div class="dialog-panel__content">
            <slot />
            <MessagePane
                v-if="error?.length > 0"
                type="error">
              <ul>
                <li
                    v-for="(message, idx) in error"
                    :key="idx">
                  {{ message }}
                </li>
              </ul>
            </MessagePane>
          </div>
        </div>
      </template>
      <template #buttons>
        <slot
            v-if="cancel"
            name="cancel">
          <IconButton
              type="action"
              @click="$emit('close')">
            <template #icon>
              <CancelIcon />
            </template>
            {{ t('cancel') }}
          </IconButton>
        </slot>
        <slot
            name="ok"
            :confirm="doConfirm">
          <LoadingButton
              :type="(error) ? 'error': confirmType"
              :enabled="!error?.length"
              :indefinite="true"
              :loading="confirmLoading"
              @click="doConfirm">
            <template #icon>
              <ErrorIcon v-if="error" />
              <template v-else-if="cancel">
                <slot name="okIcon">
                  <OkIcon />
                </slot>
              </template>
              <CancelIcon v-else />
            </template>
            <slot name="okText">
              {{ t('ok') }}
            </slot>
          </LoadingButton>
        </slot>
      </template>
    </DialogActionPane>
  </WindowPanel>
</template>
<i18n>
{
  "nl": {
    "ok": "Oké",
    "cancel": "Annuleren"
  },
  "en": {
    "ok": "OK",
    "cancel": "Cancel"
  }
}
</i18n>
<script>
import InfoIcon from '@carbon/icons-vue/es/information/24.js';
import CancelIcon from '@carbon/icons-vue/es/close/24.js';
import OkIcon from '@carbon/icons-vue/es/checkmark/24.js';
import ErrorIcon from '@carbon/icons-vue/es/error/24.js';
import WarningIcon from '@carbon/icons-vue/es/warning--alt/32.js';
import { useI18n } from 'vue-i18n';
import {
  ref,
  toRefs,
} from 'vue';
import WindowPanel from './WindowPanel.vue';
import DialogActionPane from '../panels/DialogActionPane.vue';
import IconButton from '../buttons/IconButton.vue';
import { getMessages } from '../../utils/vuelidate.js';
import MessagePane from '../messages/MessagePane.vue';
import LoadingButton from '../buttons/LoadingButton.vue';
import { useShortcuts } from '../../utils/shortcuts.js';

export default {
  components: {
    LoadingButton,
    MessagePane,
    IconButton,
    DialogActionPane,
    WindowPanel,
    CancelIcon,
    OkIcon,
    WarningIcon,
    InfoIcon,
    ErrorIcon,
  },
  props: {
    type: {
      type: String,
      default: () => null,
    },
    cancel: {
      type: Boolean,
      default: () => true,
    },
    confirmType: {
      type: String,
      default: () => undefined,
    },
    confirm: {
      type: [Function, Promise],
      default: () => () => false,
    },
    ...WindowPanel.props,
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const { confirm } = toRefs(props);
    const { t } = useI18n();
    const error = ref(null);
    const confirmLoading = ref(false);

    const { onShortcut } = useShortcuts();

    const doConfirm = async () => {
      confirmLoading.value = true;
      emit('confirm');
      if (confirm.value) {
        try {
          await confirm.value();
        } catch (ve) {
          error.value = getMessages(ve);
        }
      }
      confirmLoading.value = false;
    };

    onShortcut({ key: 'Enter' }, async () => {
      await doConfirm();
      return true;
    });

    return {
      confirmLoading,

      t,

      error,

      doConfirm,
    };
  },
};
</script>
<style>
.dialog-panel {
  max-width: 30rem;
  min-height: 0;

  &__main {
    display: flex;
    gap: var(--dimension-medium-large);
    flex: 1;
    justify-content: center;
    align-items: center;

    padding: var(--dimension-medium-large);
  }

  &__content {
    display: flex;
    gap: var(--dimension-small);
    flex-direction: column;
  }

  &__icon {
    width: 2.5rem;
    height: auto;
  }

}
</style>
