<template>
  <router-link
      class="logo-pane"
      :to="to || {name: `${locale}:home`}">
    <LogoImage class="logo-pane__logo" />

    <div class="logo-pane__titles">
      <h2 class="logo-pane__subtitle">
        {{ t('subtitle') }}
      </h2>
      <h1 class="logo-pane__title">
        {{ t('title') }}
      </h1>
    </div>
  </router-link>
</template>
<i18n>
{
  "nl": {
    "subtitle": "Raad van Beheer",
    "title": "Sporten"
  },
  "en": {
    "subtitle": "Raad van Beheer",
    "title": "Sports"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import LogoImage from './LogoImage.vue';

export default {
  components: { LogoImage },
  props: {
    to: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
};
</script>
<style>
.logo-pane {
  @media (any-hover: hover) {
    &:hover {
      opacity: .75;
    }
  }

  display: grid;
  grid-gap: var(--dimension-small);
  grid-template-columns: max-content 1fr;
  align-items: center;

  color: inherit;

  text-decoration: none;

  transition: opacity var(--animation-default-duration);

  * {
    cursor: pointer;
  }

  &__logo {
    width: var(--dimension-medium-large);

    > img {
      width: 100%;
    }
  }

  &__titles {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-family: var(--font-header);
    font-size: 1rem;
    font-weight: normal;
    line-height: 1;
    overflow-wrap: anywhere;
  }

  &__subtitle {
    color: var(--color-secondary-header);

    font-family: var(--font-header);

    font-size: .475rem;
    font-weight: normal;
    line-height: 1.25;
  }
}

@media screen and (max-width: 768px) {
  .logo-pane {
      grid-template-columns: max-content;

    &__titles {
      display: none;
    }
  }
}
</style>
