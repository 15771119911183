<template>
  <TableHeader
      class="sortable-table-header"
      v-bind="$props"
      @click="$emit('select')">
    <slot>
      {{ label }}
    </slot>
    <Badge
        :inline="true"
        type="info">
      <SortAscIcon
          v-if="ascending === true"
          class="sortable-table-header__sort" />
      <SortDescIcon
          v-else-if="ascending === false"
          class="sortable-table-header__sort" />
      <template
          v-if="order"
          #badge>
        {{ order }}
      </template>
    </Badge>
  </TableHeader>
</template>
<script>
import SortAscIcon from '@carbon/icons-vue/es/chevron--down/16.js';
import SortDescIcon from '@carbon/icons-vue/es/chevron--up/16.js';
import TableHeader from './TableHeader.vue';
import Badge from '../tag/Badge.vue';

export default {
  components: {
    Badge,
    SortAscIcon,
    SortDescIcon,
    TableHeader,
  },
  props: {
    ...TableHeader.props,
    order: {
      type: Number,
      default: () => null,
    },
    label: {
      type: String,
      default: () => null,
    },
    ascending: {
      type: Boolean,
      default: () => null,
    },
  },
  emits: ['select'],
};
</script>
<style>
.sortable-table-header {
  cursor: pointer;

  user-select: none;

  &__sort {
    display: inline-block;

    padding: var(--dimension-xx-small);

    vertical-align: middle;
  }
}
</style>
