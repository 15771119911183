import {
  computed,
  reactive,
  ref,
} from 'vue';
import { toSorted } from '../../utils/polyfills.js';

export default function useTableSort(columns, itemsRef) {
  const max = ref(0);
  const columnsRef = reactive(Object.fromEntries(Object.entries(columns).map(
    ([name, { label, compare }]) => [
      name, {
        label,
        compare,
        ascending: null,
        order: 0,
        max,
        select() {
          this.order = max.value;
          max.value += 1;

          this.ascending = !this.ascending;
        },
      },
    ],
  )));
  const compare = (a, b) => {
    if (!columnsRef) {
      return 0;
    }
    let order = 0;
    const sortable = toSorted(
      Object.values(columnsRef).filter((c) => c.ascending !== null),
      (aa, bb) => aa.order - bb.order,
    );
    while (order === 0 && sortable.length) {
      const c = sortable.pop();
      order = (!c.ascending) ? -c.compare(a, b) : c.compare(a, b);
    }
    return order;
  };
  return {
    columns: columnsRef,
    items: computed(() => toSorted(itemsRef.value, compare)),
  };
}
