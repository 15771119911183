<template>
  <InteractiveListItem
      class="selectable-list-item"
      v-bind="$attrs"
      tabindex="0"
      :class="{'selectable-list-item--selected': selected}"
      @keydown="handleKeyInput"
      @click="$emit('select')">
    <slot />
  </InteractiveListItem>
</template>
<script>

import InteractiveListItem from './InteractiveListItem.vue';

export default {
  components: { InteractiveListItem },
  inheritAttrs: false,
  props: {
    selected: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    return {
      handleKeyInput(ev) {
        if (ev.key === 'Enter') {
          ev.stopPropagation();
          ev.preventDefault();
          emit('select');
        }
      },
    };
  },
};
</script>
<style>
.selectable-list-item {
  @media (any-hover: hover) {
    &.interactive-list-item--enabled:hover {
      background-position: 0 66%;
    }
  }
  background-image: linear-gradient(
      to bottom,
      var(--list-item-background) 0%,
      var(--list-item-background) 25%,
      color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 25%,
      color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 75%,
      var(--color-secondary-complement-background) 75%,
      var(--color-secondary-complement-background) 100%
  );
  background-size: 100% 400%;

  &--selected {
    background-position: 0 100%;
  }

  &:nth-child(2n + 1) {
    background-image: linear-gradient(
        to bottom,
        var(--list-item-alternate-background) 0%,
        var(--list-item-alternate-background) 25%,
        color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 25%,
        color-mix(in srgb, var(--list-item-alternate-background), var(--color-default-background)) 75%,
        var(--color-secondary-complement-background) 75%,
        var(--color-secondary-complement-background) 100%
    );
  }

  &:focus-within {
    color: var(--color-secondary-complement-foreground);
    background-position: 0 100%;
  }

}
</style>
