<template>
  <div
      class="search-select-input"
      :class="{'search-select-input--empty': !modelValue}">
    <div class="search-select-input__widget">
      <div
          class="search-select-input__input">
        <TextInput
            :id="id"
            ref="searchEl"
            class="search-select-input__text-input"
            :model-value="q"
            :placeholder="placeholder"
            :name="name"
            @focus="hasFocus = true"
            @update:model-value="v => $emit('update:q', v)"
            @keydown="handleKeyInput" />
        <SearchIcon class="search-select-input__icon" />
      </div>
      <InsetPane
          class="search-select-input__selected"
          type="small"
          @click="hasFocus = true">
        <slot
            name="selected"
            :item="modelValue">
          <slot
              name="item"
              :item="modelValue" />
        </slot>
      </InsetPane>
    </div>
    <div
        v-if="q"
        :style="{maxHeight}"
        class="search-select-input__results">
      <LoaderPane :loading="loading">
        <ScrollablePane>
          <slot
              name="container"
              :items="selectedResults"
              :select="select">
            <ListContainer
                ref="list"
                :items="selectedResults">
              <template #item="{item}">
                <SelectableListItem
                    :selected="item.selected"
                    class="search-select-input__item"
                    @select="select(item)">
                  <slot
                      name="item"
                      :item="item" />
                </SelectableListItem>
              </template>
            </ListContainer>
          </slot>
          <EmptyPane v-if="!loading && selectedResults?.length === 0">
            {{ t('nothingFound', { q }) }}
          </EmptyPane>
        </ScrollablePane>
      </LoaderPane>
    </div>
  </div>
</template>
<i18n>
{
  "nl": {
    "nothingFound": "Niets gevonden voor `{q}`"
  }
}
</i18n>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  nextTick,
  ref,
  toRefs,
  watch,
} from 'vue';
import SearchIcon from '@carbon/icons-vue/es/search/24';
import TextInput from '../widgets/forms/TextInput.vue';
import ScrollablePane from '../panels/ScrollablePane.vue';
import ListContainer from '../list/ListContainer.vue';
import LoaderPane from '../loading/LoaderPane.vue';
import SelectableListItem from '../list/SelectableListItem.vue';
import EmptyPane from '../panels/EmptyPane.vue';
import InsetPane from '../panels/InsetPane.vue';

export default {
  components: {
    InsetPane,
    EmptyPane,
    SelectableListItem,
    LoaderPane,
    ListContainer,
    ScrollablePane,
    TextInput,
    SearchIcon,
  },
  props: {
    ...TextInput.props,
    modelValue: {
      type: [Object, null],
      required: true,
    },
    results: {
      type: Array,
      required: true,
    },
    q: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: () => null,
    },
    maxHeight: {
      type: String,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    resetOnSelect: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['search', 'update:q', 'update:model-value', 'select'],
  setup(props, { emit }) {
    // i18n
    const { t } = useI18n();

    // Refs
    const {
      results, q, modelValue, resetOnSelect,
    } = toRefs(props);
    const searchEl = ref(null);
    const list = ref(null);
    const selected = ref(-1);
    const hasFocus = ref(false);

    const select = async (item) => {
      selected.value = -1;

      if (!resetOnSelect.value) {
        searchEl.value?.focus();
        searchEl.value?.blur();
        hasFocus.value = false;
      }

      if (item && resetOnSelect.value) {
        searchEl.value?.focus();
        hasFocus.value = true;
      }

      emit('update:q', '');
      emit('update:model-value', item);
      emit('select', item);

    };

    watch(hasFocus, async (n, o) => {
      if (n && !o) {
        await nextTick();
        searchEl?.value.focus();
      }
    });

    watch(q, (n, o) => {
      if (!n && o && modelValue.value && resetOnSelect.value) {
        emit('update:model-value', null);
      }
      if (n?.length) {
        selected.value = 0;
      }
    });

    return {
      t,
      searchEl,
      list,
      select,

      selectedResults: computed(() => results.value?.map(
        (r, idx) => ({ ...r, selected: selected.value === idx }),
      )),
      hasFocus,
      async focus() {
        await nextTick();
        searchEl.value?.focus();
      },
      handleKeyInput(ev) {
        if (ev.key === 'Escape') {
          ev.preventDefault();
          ev.stopPropagation();
          if (q.value === '') {
            ev.target.blur();
          } else {
            emit('update:q', '');
          }
        } else if (ev.key === 'ArrowDown') {
          selected.value = (selected.value >= results.value.length - 1) ? 0 : selected.value + 1;
          list.value?.scrollToItem(selected.value);
          ev.preventDefault();
          ev.stopPropagation();
        } else if (ev.key === 'ArrowUp') {
          selected.value = (selected.value <= 0) ? results.value.length - 1 : selected.value - 1;
          list.value?.scrollToItem(selected.value);
          ev.preventDefault();
          ev.stopPropagation();
        } else if (ev.key === 'Enter') {
          if (results.value && results.value[selected.value]) {
            select(results.value[selected.value]);
          }
          ev.preventDefault();
          ev.stopPropagation();
        }
      },
    };
  },
};
</script>
<style>
.search-select-input {
  position: relative;
  display: flex;
  flex: 1;

  &__widget {
    display: flex;
    flex: 1;
  }

  &__input {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: rgba(var(--rgb-default-foreground), .25);

    opacity: 0;
  }

  &__icon {
    margin-right: var(--dimension-x-small);
  }

  &__selected {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    pointer-events: none;
  }

  &__results {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: var(--layer-popover);
    display: none;

    max-height: calc(100dvh - 15rem);

    color: var(--color-default-foreground);

    background-color: var(--color-default-background);
    border-top: var(--dimension-line) solid var(--color-primary-background);
    box-shadow: 0 .25rem 1rem -.125rem rgba(var(--rgb-default-foreground), .1);

  }

  &__item {
    padding: var(--dimension-small);
  }

  &:focus-within &__results {
    display: flex;
  }

  &:focus-within &__selected {
    display: none
  }

  &--empty &__input,
  &:focus-within &__input {
    color: var(--color-default-foreground);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .search-select-input {
    position: static;

    &__results {
      right: var(--dimension-small);
      left: var(--dimension-small);

    }
  }
}
</style>
