<template>
  <div
      :style="{height: `${totalHeight.toFixed(2)}rem`}"
      class="day-schedule-pane">
    <ul class="day-schedule-pane__container">
      <li
          v-for="item in annotatedItems"
          :key="item.id"
          :style="{
            top: `${item.y.toFixed(2)}rem`,
            height: `${item.height.toFixed(2)}rem`,
            minHeight: `${item.height.toFixed(2)}rem`,
          }"
          class="day-schedule-pane__item">
        <slot
            name="item"
            :item="item" />
      </li>
      <li
          class="day-schedule-pane__now"
          :style="{top: `${nowY.toFixed(2)}rem`}" />
    </ul>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';
import {
  computed,
  toRefs,
} from 'vue';
import { differenceInMilliseconds } from 'date-fns';
import useNow from '../../utils/date.js';

export default {
  components: {},
  props: {
    items: {
      type: Object,
      required: true,
    },
    range: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // refs
    const { items, range } = toRefs(props);

    // uses
    const { t } = useI18n();
    const now = useNow(60 * 1000);

    // computed
    const scaleFactor = computed(() => 250000);
    const distance = (a, b) => differenceInMilliseconds(b, a) / scaleFactor.value;
    const start = computed(() => range.value.start);
    const totalHeight = computed(() => distance(
      start.value,
      range.value.end,
    ));

    const annotatedItems = computed(() => items.value?.map(
      (item) => ({
        ...item,
        y: distance(
          start.value,
          item.duration.start,
        ),
        height: distance(
          item.duration.start,
          item.duration.end,
        ),
      }),
    ));
    const nowY = computed(() => distance(
      start.value,
      now.value,
    ));

    // methods

    return {
      // refs

      // uses
      t,

      // computed
      annotatedItems,
      totalHeight,
      nowY,
    };
  },
};
</script>
<style>
.day-schedule-pane {
  @media (any-hover: hover) {
    &__item:hover {
      z-index: var(--layer-menu);

      height: auto !important;
    }
  }
  display: grid;

  &__container {
    position: relative;
  }

  &__item {
    position: absolute;
    right: 0;
    left: 0;
    display: grid;

    overflow: hidden;
  }

  &__now {
    position: absolute;
    right: 0;
    left: 0;
    z-index: var(--layer-menu);

    height: var(--dimension-thick-line);
    margin-top: calc(-1 * (var(--dimension-thick-line) / 2));

    background-color: rgba(var(--rgb-primary-background), .75);
  }
}
</style>
